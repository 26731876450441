export default [
  {
    key: 'leaveTypeId',
    label: 'field.leaveType',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'leaveType',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'salespersonId',
    label: 'field.salesperson',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'salesperson',
    selectionKey: 'id',
    selectionLabel: 'label',
  },
  {
    key: 'departmentId',
    label: 'field.department',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'department',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'positionId',
    label: 'field.position',
    type: 'async-multi-selection',
    operator: 'in',
    repository: 'position',
    selectionKey: 'id',
    selectionLabel: 'name',
    localization: true,
  },
  {
    key: 'state',
    label: 'field.state',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'multi-selection',
    operator: 'in',
    translatable: true,
    options: [
      { text: 'leaveState.1', value: '1' },
      { text: 'leaveState.2', value: '2' },
      { text: 'leaveState.3', value: '3' },
      { text: 'leaveState.4', value: '4' },
      { text: 'leaveState.5', value: '5' },
      { text: 'leaveState.0', value: '0' },
      { text: 'leaveState.6', value: '6' },
    ],
  },
  {
    key: 'requestedDate',
    label: 'field.requestedDate',
    type: 'date-range-picker',
    cast: 'range',
    firstSearchField: "toDate",
    secondSearchField: "fromDate",
    firstSuffix: "00:00:00",
    secondSuffix: "23:59:59",
    firstOperator: 'dt>=',
    secondOperator: 'dt<=',
  },
]
