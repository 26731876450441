export default [
  {
    key: 'salespersonIdCard',
    label: 'field.idCard',
    width: 3000,
  },
  {
    key: 'salespersonName',
    label: 'field.salesperson',
    width: 7000,
  },
  {
    key: 'positionName',
    label: 'field.position',
    localization: true,
    width: 9000,
  },
  {
    key: 'departmentName',
    label: 'field.department',
    localization: true,
    width: 7000,
  },
  {
    key: 'leaveTypeName',
    label: 'field.leaveType',
    localization: true,
  },
  {
    key: 'leaveTypeCode',
    label: 'field.code',
    width: 2000,
  },
  {
    key: 'quantity',
    label: 'field.quantity',
    width: 2000,
  },
  {
    key: 'date',
    label: 'field.date',
    width: 3000,
  },
  {
    key: 'fromDate',
    label: 'field.fromDate',
    width: 3000,
  },
  {
    key: 'toDate',
    label: 'field.toDate',
    width: 3000,
  },
  {
    key: 'state',
    label: 'field.state',
    width: 5000,
    options: ["leaveState.0", "leaveState.1", "leaveState.2", "leaveState.3", "leaveState.4", "leaveState.5"],
  },
  {
    key: 'reason',
    label: 'field.reason',
    width: 10000,
  },
  {
    key: 'backupPerson',
    label: 'field.backupPerson',
    width: 10000,
  },
  {
    key: 'createdAt',
    label: 'field.requestedDate',
  },
];
