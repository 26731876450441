export default [
  {
    key: 'increasement',
    sortable: true,
    sortField: 'id',
    label: '#',
    hideToggle: true,
    thClass: 'w-50px text-center',
    tdClass: 'text-center',
  },
  {
    key: 'salespersonName',
    label: 'field.salesperson',
    subKey: 'salespersonIdCard',
    hideToggle: true,
  },
  {
    key: 'positionName',
    label: 'field.position',
    localization: true,
  },
  {
    key: 'departmentName',
    label: 'field.department',
    localization: true,
  },
  {
    key: 'leaveTypeName',
    label: 'field.leaveType',
    subKey: 'leaveTypeCode',
    localization: true,
    hideToggle: true,
  },
  {
    key: 'quantity',
    label: 'field.quantity',
    thClass: 'text-center',
    tdClass: 'text-center',
    hideToggle: true,
  },
  {
    key: 'date',
    label: 'field.date',
    type: 'date',
    hideToggle: true,
  },
  {
    key: 'fromDate',
    label: 'field.fromDate',
    type: 'time',
    hideToggle: true,
  },
  {
    key: 'toDate',
    label: 'field.toDate',
    type: 'time',
    hideToggle: true,
  },
  {
    key: 'state',
    label: 'field.state',
    hideToggle: true,
  },
  {
    key: 'reason',
    label: 'field.reason',
  },
  {
    key: 'backupPerson',
    label: 'field.backupPerson',
  },
  {
    key: 'note',
    label: 'field.note',
  },
  {
    key: 'createdAt',
    sortable: true,
    sortField: 'createdAt',
    label: 'field.requestedDate',
    hideToggle: true,
  },
];
